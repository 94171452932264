<template lang="pug">
.wrapper.d-flex.align-items-center(style="height: 100vh")
  .container.mb-5
    .row
      .col
        .d-flex.align-items-center.justify-content-center
          img(style="width: 15.625rem; margin-left: -2.1875rem" :src="require('@/assets/admin/svg/happy-monk.svg')")
    .row.mt-4
      .col.pb-3
        h2.d-flex.align-items-center.justify-content-center.pb-1 {{ $t('notifyMe.accepted') }}
        h6.d-flex.align-items-center.justify-content-center.mt-2 {{ $t('notifyMe.notification') }}
    .row.mt-5
      .col
        .d-flex.align-items-center.justify-content-center
          a.brand-btn.brand-btn-primary.brand-btn-lg.px-5.font-size-1--125(:href="lang === 'hu' ? 'https://optimonk.hu' : 'https://optimonk.com'") {{ $t(`notifyMe.goToSales`) }}
</template>

<script>
  import VALIDATE_NOTIFY_ME_INVITATION from '@/graphql/ValidateNotifyMeInvitation.gql';

  export default {
    name: 'NotifyMe',
    data() {
      return {
        lang: 'en',
      };
    },
    created() {
      this.lang = this.$route.params.lang === 'hu' ? 'Hu' : 'En';
      this.$apollo.query({
        query: VALIDATE_NOTIFY_ME_INVITATION,
        variables: {
          hash: this.$route.params.hash,
          userId: this.$route.params.dbId,
        },
      });
    },
  };
</script>
